import React from "react";
import Layout from "../../components/Layout";
import Policy from "./Policy";

const Privacy = () => {
	return (
		<Layout>
			<div style={{ marginTop: "6rem", width: "100%", display: "flex" }}>
				<Policy />
			</div>
		</Layout>
	);
};

export default Privacy;
